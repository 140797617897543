<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="6" sm="6">
            <ValidationProvider name="Price Collection Date" vid="fair_price_created_date" rules="required">
              <b-form-group
                label-for="fair_price_created_date"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{ $t('priceMonitoring.fair_price_created_date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="fair_price_created_date"
                  v-model="form.fair_price_created_date"
                  class="form-control"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :placeholder="$t('globalTrans.select')"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="6" sm="6">
            <ValidationProvider name="Approver name (En)" vid="approver_name" rules="required|min_value:1">
              <b-form-group
                label-for="approver_name"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('priceMonitoring.approver_name')}} <span class="text-danger">*</span>
              </template>
              <b-form-radio-group
                v-model="form.approver_id"
                :options="approverList"
                class="mb-3"
                :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-radio-group>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="6" sm="6">
            <ValidationProvider name="Commodity Group" vid="commodity_group_id" rules="required|min_value:1">
              <b-form-group
                label-for="commodity_group_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('bazarMonitoring.commodity_group')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.commodity_group_id"
                :options="commodityGroupList"
                id="commodity_group_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <ValidationProvider name="Commodity Name" vid="commodity_name_id" rules="required|min_value:1">
              <b-form-group
                label-for="commodity_name_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('bazarMonitoring.commodity_name')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.commodity_name_id"
                :options="commodityNameList"
                id="commodity_name_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <ValidationProvider name="Measurement Unit" vid="measurement_unit" rules="required|min_value:1">
              <b-form-group
                label-for="measurement_unit"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('bazarMonitoring.measurement_unit')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.measurement_unit_id"
                :options="unitList"
                id="measurement_unit"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <ValidationProvider name="Fair Price" vid="retail_fair_price" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="fair_price">
                <template v-slot:label>
                  {{ $t('priceMonitoring.fair_price') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="fair_price"
                  v-model="form.fair_price"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row v-if="details.length > 0">
          <b-table-simple hover small responsive bordered>
            <b-tr style="background: #f3f3f3">
              <b-th style="width: 10%; vertical-align:middle" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
              <b-th style="width: 30%; vertical-align:middle" class="text-center">{{ $t('priceMonitoring.fair_price_created_date') }}</b-th>
              <b-th style="width: 30%; vertical-align:middle" class="text-center">{{ $t('priceMonitoring.fair_price') }}</b-th>
            </b-tr>
            <template v-for="(item, index) in details">
              <b-tr :key="index ">
                <b-td class="text-center" style="vertical-align:middle">{{ $n(index+1) }}</b-td>
                <b-td class="text-center" style="vertical-align:middle">
                  {{ item.fair_price_created_date | dateFormat }}
                </b-td>
                <b-td class="text-center" style="vertical-align:middle">
                  {{ $n(item.fair_price )}}
                </b-td>
              </b-tr>
            </template>
          </b-table-simple>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { fairPriceStore, fairPriceUpdate, fairPriceCategory } from '../../api/routes'
const today = new Date().toISOString().substr(0, 10)

export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      details: [],
      commodityNameList: [],
      form: {
        fair_price_created_date: today,
        commodity_group_id: 0,
        commodity_name_id: 0,
        approver_id: 0,
        measurement_unit_id: 0,
        fair_price: null
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getApprover()
      this.form = tmp
    }
  },
  watch: {
    'form.commodity_group_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.commodityNameList = this.getCommodityNameList(newVal)
      } else {
        this.commodityNameList = []
      }
    },
    'form.commodity_name_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const unit = this.$store.state.BazarMonitoringService.commonObj.unitList.find(item => item.commodity_name_id === parseInt(newVal))
        this.form.measurement_unit_id = unit.value
        this.loadApi()
      }
    }
  },
  computed: {
    approverList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'MOC' : 'বাণিজ্য মন্ত্রণালয়' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'DAM' : 'ডিএএম' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'BTTC' : 'বি টি টি সি' }
      ]
      return list
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    commodityGroupList () {
      return this.$store.state.BazarMonitoringService.commonObj.commodityGroupList.filter(item => item.status === 1)
    },
    unitList () {
      return this.$store.state.BazarMonitoringService.commonObj.unitList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCommodityNameList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.commodityNameList.filter(item => item.status === 1 && item.commodity_group_id === id)
    },
    async loadApi () {
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, fairPriceCategory, this.form)
      if (result.success) {
        this.details = result.data
      }
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(bazarMonitoringServiceBaseUrl, `${fairPriceUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(bazarMonitoringServiceBaseUrl, fairPriceStore, this.form)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getApprover () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
